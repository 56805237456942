/* body {
	margin: 0;
	padding: 0;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #e5e5e5;
} */

.middle {
	position: relative;
	width: 50%;
	max-width: 500px;
}

.slider {
	position: relative;
	z-index: 1;
	height: 10px;
	margin: 0 15px;
}
.slider > .track {
	position: absolute;
	z-index: 1;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	border-radius: 5px;
	background-color: #c6aee7;
}
.slider > .range {
	position: absolute;
	z-index: 2;
	left: 25%;
	right: 25%;
	top: 0;
	bottom: 0;
	border-radius: 5px;
	background-color: #6200ee;
}
.slider > .thumb {
	position: absolute;
	z-index: 3;
	width: 30px;
	height: 30px;
	background-color: #6200ee;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(98,0,238,.1);
	transition: box-shadow .3s ease-in-out;
}
.slider > .thumb.left {
	left: 25%;
	transform: translate(-15px, -10px);
}
.slider > .thumb.right {
	right: 25%;
	transform: translate(15px, -10px);
}
.slider > .thumb.hover {
	box-shadow: 0 0 0 20px rgba(98,0,238,.1);
}
.slider > .thumb.active {
	box-shadow: 0 0 0 40px rgba(98,0,238,.2);
}

input[type=range] {
	position: absolute;
	pointer-events: none;
	-webkit-appearance: none;
	z-index: 2;
	height: 10px;
	width: 100%;
	opacity: 0;
}
input[type=range]::-webkit-slider-thumb {
	pointer-events: all;
	width: 30px;
	height: 30px;
	border-radius: 0;
	border: 0 none;
	background-color: red;
	-webkit-appearance: none;
}


.youtube-link {
	position: fixed;
	left: 20px;
	bottom: 20px;
	color: #000;
	text-decoration: none;
	font-size: 12px;
}
