.card {
  height: 450px;
  width: 30.7%;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  margin: 1em;
}

.single-card {
  height: 400px;
  width: 100%;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  margin: 1em;
}

.panel: {
  height: 350;
}

@media only screen and (max-width: 959px) {
  .card {
    width: 100%;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1280px) {
  .card {
    width: 46%;
  }
}

@media only screen and (min-width: 1700px) {
  .card {
    width: 31%;
  }
}
